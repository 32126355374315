import React, { Component } from "react";
import IconButton from "@material-ui/core/IconButton";
import EmailIcon from "@material-ui/icons/Email";
import { withStyles } from "@material-ui/core";

const styles = {
  logo: {
    position: "absolute",
    top: "10px",
    left: "10px"
  },
  email: {
    position: "absolute",
    top: "10px",
    right: "10px"
  }
};
class Header extends Component {
  render() {
    const { classes } = this.props;
    return (
      <header>
        <div className="header">
          <div className="header_content">
            <div className={classes.logo}>
              <a href="/">
                <img src="./images/Mondus2.png?v=1.0" alt="" className="logo" />
              </a>
            </div>
            <div className={classes.email}>
              <IconButton href="mailto:info@mond.us">
                <EmailIcon style={{ color: "#444" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default withStyles(styles)(Header);
