import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import UXIcon from "@material-ui/icons/Dashboard";
import ThirdPartyIcon from "@material-ui/icons/Extension";
import DatabaseIcon from "@material-ui/icons/Storage";
import ServerIcon from "@material-ui/icons/OfflineBolt";
import CodeArchitectureIcon from "@material-ui/icons/Layers";
import { withStyles } from "@material-ui/core";

const styles = {
  icon: {
    fontSize: "27px"
  }
};

class Home extends Component {
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Header />
        <main>
          <div className="body">
            <div className="body_content">
              <h2>About Mondus Consulting</h2>
              <p className="bigger">
                Mondus Consulting delivers data driven applications for the internet and mobile
                internet experience. We have been architecting and designing business software
                applications for over 20 years. Mondus specializes in light, fast and scalable
                solutions. Utilizing an elite and agile team of accomplished developers we deliver
                solutions in the cloud for demanding businesses. To learn more about Mondus please
                contact us directly.
              </p>
              <div style={{ width: "100%", marginTop: "20px" }}>
                <h2>Areas of Expertise</h2>
              </div>
              <div className="content_section_container">
                <div className="content_section">
                  <div className="flex-row">
                    <UXIcon className={classes.icon} />
                    <div className="margin-left-10">
                      <h4>User Experience and Site Design</h4>
                      <ul>
                        <li>Mobile First / Responsive Design</li>
                        <li>Rich and Intuitive User Experiences</li>
                        <li>Target all Modern Browsers and Smart Phones</li>
                        <li>Light, Simple, and Clean Interfaces</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="content_section">
                  <div className="flex-row">
                    <ThirdPartyIcon className={classes.icon} />
                    <div className="margin-left-10">
                      <h4>3rd Party API Integrations</h4>
                      <ul>
                        <li>OAuth2.0, OAuth1.0</li>
                        <li>Facebook, Twitter, Salesforce, Yelp, LinkedIn</li>
                        <li>Google+, Google Maps, Google Places, Google Docs</li>
                        <li>Authorize.NET, PayPal, Gmail, Hotmail, Yahoo</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="content_section_container">
                <div className="content_section">
                  <div className="flex-row">
                    <CodeArchitectureIcon className={classes.icon} />
                    <div className="margin-left-10">
                      <h4>Code Architecture and Design</h4>
                      <ul>
                        <li>React, React Native, Redux, Sagas, Webpack, Node.js</li>
                        <li>
                          JavaScript, Golang, C#, Json, jQuery, Ajax, XML, .NET, MVC, HTML, HTML5,
                          CSS, CSS3
                        </li>
                        <li>PDF, Excel, and Google Docs based Reporting</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="content_section">
                  <div className="flex-row">
                    <DatabaseIcon className={classes.icon} />
                    <div className="margin-left-10">
                      <h4>Database Architecture and Design</h4>
                      <ul>
                        <li>PostgreSQL, MongoDB, MySQL, SQL Server</li>
                        <li>NoSQL or Relationial design, Indexing, Tuning, Replication</li>
                        <li>Stored Procedures, Functions, Views, Profiling, Auditing</li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="content_section">
                  <div className="flex-row">
                    <ServerIcon className={classes.icon} />
                    <div className="margin-left-10">
                      <h4>Server Architecture and Design</h4>
                      <ul>
                        <li>
                          Amazon EC2, VPC, RDS, Beanstalk, OpsWorks, Scaling, Load Balancing, S3,
                          Cloud Front
                        </li>
                        <li>Linux, Ubuntu, Apache, Nginx, Windows Server/IIS</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ clear: "both" }} />
            </div>
          </div>
        </main>
        <Footer />
      </Fragment>
    );
  }
}

export default withStyles(styles)(Home);
