import React, { Component } from "react";
import "./css/reset.css";
import "./css/form.css";
import "./css/layout.css";
import "./css/site.css";
import "./css/menu.css";
import { Switch, Route } from "react-router-dom";
import Home from "./pages/Home";

class App extends Component {
  setupParticlesJs() {
    var lineColor = "#fff";
    let particlesJS = window && window.particlesJS;
    particlesJS("particles-js", {
      particles: {
        number: {
          value: 118,
          density: {
            enable: true,
            value_area: 710.2328774690454
          }
        },
        color: {
          value: lineColor
        },
        shape: {
          type: "edge",
          stroke: {
            width: 0,
            color: "#000000"
          },
          polygon: {
            nb_sides: 7
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100
          }
        },
        opacity: {
          value: 0.07680624057955,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false
          }
        },
        size: {
          value: 0,
          random: true,
          anim: {
            enable: false,
            speed: 5.59040959040959,
            size_min: 34.36563436563437,
            sync: false
          }
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: lineColor,
          opacity: 0.4,
          width: 1
        },
        move: {
          enable: true,
          speed: 2.5,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200
          }
        }
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: false,
            mode: "grab"
          },
          onclick: {
            enable: false,
            mode: "push"
          },
          resize: true
        },
        modes: {
          grab: {
            distance: 400,
            line_linked: {
              opacity: 1
            }
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3
          },
          repulse: {
            distance: 200,
            duration: 0.4
          },
          push: {
            particles_nb: 4
          },
          remove: {
            particles_nb: 2
          }
        }
      },
      retina_detect: true
    });
  }

  componentDidMount() {
    this.setupParticlesJs();
  }

  render() {
    return (
      <Switch>
        <Route exact path="/" component={Home} />
      </Switch>
    );
  }
}

export default App;
